<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div class="container-box">
        <b-col md="8">
          <h1 class="mr-sm-4 header-tablepage">Import Data</h1>
        </b-col>
      </div>
      <div class="bg-white border-red px-1 px-sm-3 pb-3 mt-3">
        <b-row class="no-gutters mt-3">
          <b-col md="12" class="justify-content-start">
            <UploadFileV2
              textFloat="File"
              placeholder="Please choose file"
              format="excel"
              name="file"
              text="*Please upload only file .xlsx less than 10 MB"
              isRequired
              v-on:onFileChange="onFileChange"
              v-on:delete="deleteFile"
              :fileName="filename"
              v-model="form.excel_file"
              :v="$v.form.excel_file"
              id="uploadfile"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button size="md" href="/transaction" class="btn-cancel"
              >Cancel</b-button
            >
          </b-col>
          <b-col class="text-right">
            <b-button
              id="upload"
              class="main-color btn-save color-btn"
              size="md"
              :disabled="isDisable"
              @click="importData"
              >Upload</b-button
            >
          </b-col>
        </b-row>
      </div>
      <ModalInputEmail
        ref="ModalInputEmail"
        :form="form"
        @changeEmail="(val) => (form.email = val)"
        @submit="sendForm"
      />
    </div>
  </div>
</template>

<script>
import UploadFileV2 from "@/components/inputs/UploadFileV2";
import ModalInputEmail from "@/components/customer/ModalInputEmail";
import OtherLoading from "@/components/loading/OtherLoading";
import { required } from "vuelidate/lib/validators";
export default {
  components: {
    UploadFileV2,
    ModalInputEmail,
    OtherLoading,
  },
  validations: {
    form: {
      excel_file: { required },
    },
  },
  data() {
    return {
      isDisable: true,
      form: {
        excel_file: "",
        email: "",
      },
      filename: "",
      isLoading: false,
    };
  },
  created() {
    this.form.email = this.$cookies.get("back_office_admin_email");
  },
  methods: {
    onFileChange(file) {
      this.filename = file.name;
      this.isDisable = false;
      const str =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.form.excel_file = reader.result.substring(
          str.length,
          reader.result.length
        );
      };
    },
    deleteFile(value) {
      this.form.excel_file = null;
      this.filename = null;
      this.isDisable = true;
    },
    importData(id) {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$refs.ModalInputEmail.show();
    },
    async sendForm() {
      this.isLoading = true;
      this.$store.commit("setImportFile", this.form);
      await this.$store.dispatch("fileTransaction", this.form);
      let data = this.$store.state.importFile.respImportTransaction;
      if (data.result === 1) {
        this.isLoading = false;
        this.isDisable = true;
        this.$swal("Import Success!", {
          icon: "success",
        }).then(function () {
          window.location.href = "/transaction";
        });
      } else {
        if (data.detail) {
          this.$store.commit("setResponseFileError", data.detail);
          this.$router.push("/transaction/checkField");
        } else {
          this.isLoading = false;
          this.$swal(data.message, {
            icon: "warning",
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.color-btn {
  color: #fff;
}
</style>